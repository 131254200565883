import $ from 'jquery';

window.sendEmail = function sendEmail(name, host) {
	try {
		window.trackEvent('Send Email via Contact Card', document.title.substr(0, document.title.indexOf(' - ')), $.trim(name) + '@' + $.trim(host));
	} catch (err) {
		// Dont display anything
	}
	window.location = 'mailto:' + $.trim(name) + '@' + $.trim(host);
};


window._sessionProducts = window._sessionProducts || [];

window.trackEventIfValid = function trackEventIfValid(category, action, label, value) {
    if (typeof Page_ClientValidate !== 'undefined') {
        if (Page_ClientValidate()) {
            while (category.lastIndexOf('/') == category.length - 1) {
                category = category.slice(0, category.lastIndexOf('/'));
            }
        	window.pushToGTM('_trackEvent', category, action, label, value);
		}
    } else {
        while (category.lastIndexOf('/') == category.length - 1) {
            category = category.slice(0, category.lastIndexOf('/'));
        }
        window.pushToGTM('_trackEvent', category, action, label, value);
    }
}

window.trackContactEvent = function trackContactEvent(category, action, label, value) {
    var pattern = /contacts\/(.*?)\//;
    var category = "contact " + pattern.exec(category)[1] + " form submit";

    if (!action) {
        action = "contact us";
    }
    while (label.lastIndexOf('/') == label.length - 1) {
        label = label.slice(0, label.lastIndexOf('/'));
    }

    window.trackEventIfValid(category, action, label, value);
}

window.trackEvent = function trackEvent(category, action, label, value) {
    while (category.lastIndexOf('/') == category.length - 1) {
        category = category.slice(0, category.lastIndexOf('/'));
    }
    window.pushToGTM('_trackEvent', category, action, label, value);
}

window.trackLogin = function trackLogin(status) {
    
}

window.trackPageView = function trackPageView(url) {
    if (typeof Page_ClientValidate !== 'undefined') {
        if (Page_ClientValidate()) {
            window.pushToGTM('_trackPageView', '', '', '', url)
        }
    } else {
        window.pushToGTM('_trackPageView', '', '', '', url)
    }
}

window.addTrans = function addTrans(transId, total) {
    var city = $('#City').val();
    var region = $('#State').val();
    var country = $('#Country').children('option').filter(':selected').text();

    if (typeof Page_ClientValidate !== 'undefined') {
        if (Page_ClientValidate()) {
            window.pushTransactionToGTM(transId, 'perstorp.com request cart', total, city, region, country);
        }
    } else {
        window.pushTransactionToGTM(transId, 'perstorp.com request cart', total, city, region, country);
    }
}

window.addItem = function addItem(transId, sku, name, category, price, quantity) {
    if (typeof Page_ClientValidate !== 'undefined') {
        if (Page_ClientValidate()) {
            _sessionProducts.push({
                'sku': sku,
                'name': name,
                'category': category,
                'price': price,
                'quantity': quantity
            });
        }
    } else {
        _sessionProducts.push({
            'sku': sku,
            'name': name,
            'category': category,
            'price': price,
            'quantity': quantity
        });
    }
}

window.trackTrans = function trackTrans() {
    if (typeof Page_ClientValidate !== 'undefined') {
        if (Page_ClientValidate()) {
            window.pushToGTM('_trackTrans', '', '', '', '');
        }
    } else {
        window.pushToGTM('_trackTrans', '', '', '', '');
    }
}

window.pushToGTM = function pushToGTM(eventName, category, action, label, value) {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': 'gaEvent',
            'eventName': eventName,
            'category': category,
            'action': action,
            'label': label,
            'value': value
        });
    }
}

window.pushTransactionToGTM = function pushTransactionToGTM(transactionId, label, total, city, region, country) {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': 'OrderComplete',
            'transactionId': transactionId,
            'label': label,
            'total': total,
            'transactionProducts': _sessionProducts,
            'city': city,
            'region': region,
            'country': country
        });

        _sessionProducts = [];
    }
}

window.generateUUID = function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}