/* eslint-disable */

/* 
We don't want to lint polyfills... 
they are very specific in how they should be written in order to work.
 */

 
import 'core-js/fn/array/from';
import 'core-js/fn/array/reduce';
import 'core-js/fn/array/includes';
import 'core-js/fn/promise';
import 'core-js/fn/symbol';
import 'core-js/fn/object/assign';
import 'whatwg-fetch';

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector
	||	Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function _(s) {
		let el = this;
		if (!document.documentElement.contains(el)) return null;
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

if (!Object.entries)
	Object.entries = function( obj ){
	var ownProps = Object.keys( obj ),
		i = ownProps.length,
		resArray = new Array(i); // preallocate the Array
	while (i--)
		resArray[i] = [ownProps[i], obj[ownProps[i]]];

	return resArray;
};

if (!Object.keys) {
	Object.keys = (function() {
	  'use strict';
	  var hasOwnProperty = Object.prototype.hasOwnProperty,
		  hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
		  dontEnums = [
			'toString',
			'toLocaleString',
			'valueOf',
			'hasOwnProperty',
			'isPrototypeOf',
			'propertyIsEnumerable',
			'constructor'
		  ],
		  dontEnumsLength = dontEnums.length;
  
	  return function(obj) {
		if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
		  throw new TypeError('Object.keys called on non-object');
		}
  
		var result = [], prop, i;
  
		for (prop in obj) {
		  if (hasOwnProperty.call(obj, prop)) {
			result.push(prop);
		  }
		}
  
		if (hasDontEnumBug) {
		  for (i = 0; i < dontEnumsLength; i++) {
			if (hasOwnProperty.call(obj, dontEnums[i])) {
			  result.push(dontEnums[i]);
			}
		  }
		}
		return result;
	  };
	}());
  }

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}

(function (arr) {
	arr.forEach(function (item) {
	  if (item.hasOwnProperty('append')) {
		return;
	  }
	  Object.defineProperty(item, 'append', {
		configurable: true,
		enumerable: true,
		writable: true,
		value: function append() {
		  var argArr = Array.prototype.slice.call(arguments),
			docFrag = document.createDocumentFragment();
		  
		  argArr.forEach(function (argItem) {
			var isNode = argItem instanceof Node;
			docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
		  });
		  
		  this.appendChild(docFrag);
		}
	  });
	});
  })([Element.prototype, Document.prototype, DocumentFragment.prototype]);

  (function () {

	if ( typeof window.CustomEvent === "function" ) return false;
  
	function CustomEvent ( event, params ) {
	  params = params || { bubbles: false, cancelable: false, detail: null };
	  var evt = document.createEvent( 'CustomEvent' );
	  evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
	  return evt;
	 }
  
	window.CustomEvent = CustomEvent;
  })();